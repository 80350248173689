import { useMemo } from "react";

import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";

export default function useExchangeRateData({
  exchangeRateList,
}: {
  exchangeRateList: ExchangeRate[];
}) {
  //TODO: 외화 단위를 공통으로 받는 함수로 변경
  const usdRate = useMemo(() => {
    return (
      exchangeRateList.find((exchangeRate) => {
        return exchangeRate.currency === "USD";
      })?.rate || 0
    );
  }, [exchangeRateList]);

  const eurRate = useMemo(() => {
    return (
      exchangeRateList.find((exchangeRate) => {
        return exchangeRate.currency === "EUR";
      })?.rate || 0
    );
  }, [exchangeRateList]);

  const cnyRate = useMemo(() => {
    return (
      exchangeRateList.find((exchangeRate) => {
        return exchangeRate.currency === "CNY";
      })?.rate || 0
    );
  }, [exchangeRateList]);

  const gbpRate = useMemo(() => {
    return (
      exchangeRateList.find((exchangeRate) => {
        return exchangeRate.currency === "GBP";
      })?.rate || 0
    );
  }, [exchangeRateList]);

  return { usdRate, eurRate, cnyRate, gbpRate };
}
