import { useState } from "react";
import { Button, Grid } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { ADMIN_BID_FREIGHT_TYPE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import ADMIN_COUPON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COUPON_QUERY";
import { CouponType } from "@sellernote/_shared/src/types/forwarding/coupon";

import Modal from "../../../Modal";

import useSnackbar from "../../../../hooks/useSnackbar";

const FreightTypeSelectModal = ({
  setOpensFreightTypeSelectModal,
  opensFreightTypeSelectModal,
  teamId,
  campaignId,
}: {
  opensFreightTypeSelectModal: boolean;
  setOpensFreightTypeSelectModal: (value: boolean) => void;
  teamId: number;
  campaignId: number;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const [freightType, setFreightType] = useState<CouponType>("all");

  const { mutate: issueCoupon } = ADMIN_COUPON_QUERY.useIssueCoupon();

  const closeModal = () => setOpensFreightTypeSelectModal(false);

  const handleCouponIssue = () => {
    issueCoupon(
      {
        teamId,
        campaignId,
        couponType: freightType,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("쿠폰이 발급되었습니다.", "success", closeModal);
        },
        onError: ({ response }) => {
          if (response?.data?.errorCode === "E314") {
            handleSnackbarOpen(
              "같은 종류의 쿠폰을 발송할 수 없습니다.",
              "error"
            );
            return;
          }

          handleSnackbarOpen("쿠폰 발급에 실패했습니다.", "error");
          return;
        },
      }
    );
  };

  return (
    <Modal
      isOpened={opensFreightTypeSelectModal}
      handleClose={() => setOpensFreightTypeSelectModal(false)}
      modalBody={
        <Grid container direction={"column"} spacing={2} alignItems={"center"}>
          <Grid item>
            <FormControl sx={{ width: 200 }}>
              <InputLabel>운송 타입</InputLabel>

              <Select
                value={freightType}
                label="운송 타입"
                onChange={(e) => setFreightType(e.target.value as CouponType)}
              >
                {[
                  ...ADMIN_BID_FREIGHT_TYPE_OPTION_LIST.map((v) => {
                    return (
                      <MenuItem key={v.value} value={v.value}>
                        {v.label}
                      </MenuItem>
                    );
                  }),
                  <MenuItem key={"all"} value={"all"}>
                    ALL
                  </MenuItem>,
                ]}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={handleCouponIssue}>
              쿠폰 발급
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export default FreightTypeSelectModal;
