import { ReactNode, useCallback, useState } from "react";
import { Alert, AlertColor, Slide, Snackbar } from "@mui/material";

import { SnackbarContext } from "./SnackbarContext";

interface SnackbarState {
  isOpen: boolean;
  message: string;
  color?: AlertColor;
}

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [showsSnackbar, setShowsSnackbar] = useState<SnackbarState>({
    isOpen: false,
    message: "",
    color: "success",
  });

  /**
   * 스낵바 오픈 핸들러
   *   - color는 옵셔널이고 기본 success
   */
  const handleSnackbarOpen = useCallback(
    (
      message: string,
      color?: AlertColor,
      onSnackbarOpenCallback?: () => void
    ) => {
      onSnackbarOpenCallback?.();
      setShowsSnackbar({ isOpen: true, message, color: color || "success" });
    },
    []
  );

  const handleSnackbarClose = useCallback(() => {
    setShowsSnackbar((prev) => ({ ...prev, isOpen: false }));
  }, []);

  return (
    <SnackbarContext.Provider value={{ handleSnackbarOpen }}>
      {children}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showsSnackbar.isOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        TransitionComponent={Slide}
        // 뷰 최상단에 보이기 위해 zIndex를 추가 현재 어드민에서 최대 10002까지 사용하는 컴포넌트가 있음
        sx={{ zIndex: 99999 }}
      >
        <Alert
          onClose={handleSnackbarClose}
          color={showsSnackbar.color}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {showsSnackbar.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
