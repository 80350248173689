import { Chip, Grid } from "@mui/material";

import { AdminShipmentItemGroup } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { BidServiceType } from "@sellernote/_shared/src/types/forwarding/bid";

import ConsolidationItemList from "./ConsolidationItemList";
import LCLAndAIRItemList from "./LCLAndAIRItemList";

export default function ItemListForLCLAndAIR({
  serviceType,
  itemGroupList,
}: {
  serviceType: BidServiceType;
  itemGroupList: AdminShipmentItemGroup[];
}) {
  return (
    <Grid container spacing={2} sx={{ padding: "50px" }}>
      <Grid item xs={12}>
        <Chip color="primary" label="컨테이너 상세정보" variant="outlined" />
      </Grid>

      {serviceType === "consolidation" ? (
        <ConsolidationItemList itemGroupList={itemGroupList} />
      ) : (
        <LCLAndAIRItemList itemGroupList={itemGroupList} />
      )}
    </Grid>
  );
}
