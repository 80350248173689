import { Grid } from "@mui/material";

import { AdminShipmentItemGroup } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { getPackageLabel } from "@sellernote/_shared/src/utils/forwarding/bid";

import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../../components/SADSTable";

type CellKey =
  | "NO"
  | "품명"
  | "포장유형"
  | "중량"
  | "CBM"
  | "위험물"
  | "2단적재"
  | "냉동/냉장여부"
  | "수량";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "NO", label: "NO", align: "right", width: 80 },
  { cellKey: "품명", label: "품명", width: 300 },
  { cellKey: "포장유형", label: "포장유형", width: 100 },
  { cellKey: "CBM", label: "CBM", align: "right", width: 100 },
  { cellKey: "중량", label: "중량", align: "right", width: 100 },
  { cellKey: "위험물", label: "위험물", align: "right", width: 100 },
  {
    cellKey: "냉동/냉장여부",
    label: "냉동/냉장여부",
    align: "right",
    width: 120,
  },
  { cellKey: "2단적재", label: "2단적재", align: "right", width: 100 },
  { cellKey: "수량", label: "수량", align: "right", width: 100 },
];

function ConsolidationItemList({
  itemGroupList,
}: {
  itemGroupList: AdminShipmentItemGroup[] | undefined;
}) {
  if (!itemGroupList) {
    return null;
  }

  const bodyRowList: TableBodyRow<CellKey>[] = (itemGroupList || []).map(
    ({ item }, i) => {
      return {
        rowKey: item.id,
        NO: {
          value: item.id,
        },
        품명: { value: item.name },
        포장유형: { value: getPackageLabel(item.packingType) },
        CBM: { value: item.cbm },
        중량: {
          value: (
            <>
              {item.weight} {item.weightUnit}
            </>
          ),
        },
        위험물: {
          value: changeBooleanValueToKr(item.isDangerous),
        },
        "2단적재": {
          value: changeBooleanValueToKr(item.canStack),
        },
        "냉동/냉장여부": {
          value: changeBooleanValueToKr(item.needRefrigeration),
        },
        수량: { value: toThousandUnitFormat(item.quantity) },
      };
    }
  );

  return (
    <Grid item xs={11}>
      <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
    </Grid>
  );
}

export default ConsolidationItemList;
