import { Alert } from "antd";
import styled from "styled-components";

const browserAlert = styled(Alert)`
  width: 330px;
  position: fixed;
  z-index: 9999;
  right: 10px;
  top: 60px;
  text-align: center;
  font-weight: bold;
`;

export default { browserAlert };
