import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { AdminShipmentItemGroup } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { BidServiceType } from "@sellernote/_shared/src/types/forwarding/bid";

import ItemListForFCL from "./ItemListForFCL";
import ItemListForLCLAndAIR from "./ItemListForLCLAndAIR";

function ItemList({
  freightType,
  serviceType,
  itemGroupList,
}: {
  freightType: FreightType;
  serviceType: BidServiceType;
  itemGroupList: AdminShipmentItemGroup[];
}) {
  if (freightType === "FCL") {
    return <ItemListForFCL itemGroupList={itemGroupList} />;
  }

  if (freightType === "LCL" || freightType === "AIR") {
    return (
      <ItemListForLCLAndAIR
        serviceType={serviceType}
        itemGroupList={itemGroupList}
      />
    );
  }

  return null;
}

export default ItemList;
