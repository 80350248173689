import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Checkbox } from "@mui/material";

import { PartnerManagerItem } from "@sellernote/_shared/src/types/forwarding/partner";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../components/LegacyTable";

type CellId = keyof PartnerManagerItem | "checkbox";

function PartnerManagersListModal({
  managers,
  showsPartnerManagersListModal,
  setShowsPartnerManagersListModal,
  partnerCheckboxSet,
  managerCheckBoxSet,
  toggleManagerCheckBox,
  initManagerCheckBoxSet,
}: {
  managers: PartnerManagerItem[];
  showsPartnerManagersListModal: boolean;
  setShowsPartnerManagersListModal: Dispatch<SetStateAction<boolean>>;
  partnerCheckboxSet: Set<number>;
  managerCheckBoxSet: Set<number>;
  toggleManagerCheckBox: (val: number) => void;
  initManagerCheckBoxSet: (values?: number[] | undefined) => void;
}) {
  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      { id: "checkbox", disablePadding: false, label: "선택" },
      { id: "name", disablePadding: false, label: "담당자이름" },
      { id: "position", disablePadding: false, label: "직함" },
      { id: "phone", disablePadding: false, label: "전화번호" },
      { id: "email", disablePadding: false, label: "이메일" },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!managers) {
      return [];
    }

    return managers.map((managerItem) => {
      const row: LegacyTableBodyRow<CellId> = {
        checkbox: (
          <Checkbox
            checked={managerCheckBoxSet.has(managerItem.id)}
            onClick={(e) => {
              e.stopPropagation();
              toggleManagerCheckBox(managerItem.id);
            }}
          />
        ),
        name: managerItem.name,
        position: managerItem.position,
        phone: managerItem.phone,
        email: managerItem.email,
      };

      return row;
    });
  }, [managerCheckBoxSet, managers, toggleManagerCheckBox]);

  const handleModalClose = useCallback(() => {
    setShowsPartnerManagersListModal(false);
  }, [setShowsPartnerManagersListModal]);

  return (
    <Modal
      isOpened={showsPartnerManagersListModal}
      handleClose={handleModalClose}
      modalBody={
        <LegacyTable
          title="파트너 매니저 리스트"
          headCells={headCells}
          rows={rows}
        />
      }
    />
  );
}

export default PartnerManagersListModal;
