import { useMemo } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { MultiLevelSelectInfo } from ".";

export default function SelectItem<V extends string | number>({
  selectInfo,
  selectInfoOnInactive,
  onChangeCallback,
  shouldSubSelectHasDefaultValue,
}: {
  selectInfo: MultiLevelSelectInfo<V> | undefined;
  /**
   * 비활성화 상태 (상위 option이 선택되지 않았을때)에 사용하는 selectInfo.
   * 비활성화 상태에서의 대표 제목, width를 가져오는데 사용한다.
   * (Root SelectItem인 경우는 사용되지 않는다)
   */
  selectInfoOnInactive?: MultiLevelSelectInfo<V> | undefined;
  onChangeCallback?: (value: string | number) => void;
  shouldSubSelectHasDefaultValue?: boolean;
}) {
  const { title, optionList, width, selectedOptionValue, handleSelect } =
    selectInfo || {};

  const isInactive = !selectInfo;

  const selectedOption = useMemo(() => {
    if (!selectedOptionValue || !optionList) return;

    const target = optionList.find((v) => v.value === selectedOptionValue);

    return target;
  }, [optionList, selectedOptionValue]);

  /**
   * 선택된 옵션이 없을때 사용하는 하위 SelectInfo.
   * 하위 옵션 유무 체크와 title 등을 결정하는데 필요하다.
   */
  const dependentSelectInfoWhenUnselected = useMemo(() => {
    const target = selectInfo || selectInfoOnInactive;

    // 선택된 옵션이 없을때 표시할 하위 옵션이므로, 아무옵션이나 선택되어도 무방하다
    return target?.optionList.find((v) => !!v.dependentSelectInfo);
  }, [selectInfo, selectInfoOnInactive]);

  const hasDependentSelectInfo = !!dependentSelectInfoWhenUnselected;

  return (
    <>
      <FormControl
        sx={{
          minWidth: `${width || selectInfoOnInactive?.width || 120}px`,
        }}
      >
        <InputLabel sx={{ marginTop: "-6px" }}>
          {title || selectInfoOnInactive?.title}
        </InputLabel>

        <Select
          size="small"
          sx={{ marginRight: "3px" }}
          onChange={(event) => {
            handleSelect && handleSelect(event.target.value as V);
            // 하위 Select가 있다면 리셋
            if (!shouldSubSelectHasDefaultValue) {
              if (selectedOption?.dependentSelectInfo) {
                selectedOption.dependentSelectInfo.handleSelect(undefined);
              }
            }

            onChangeCallback && onChangeCallback(event.target.value as V);
          }}
          value={selectedOption?.value || ""}
          disabled={!!isInactive}
        >
          {optionList?.map((option) => (
            <MenuItem key={option.value} value={option.value || ""}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {hasDependentSelectInfo && (
        <SelectItem
          selectInfoOnInactive={
            dependentSelectInfoWhenUnselected.dependentSelectInfo
          }
          selectInfo={selectedOption?.dependentSelectInfo}
          shouldSubSelectHasDefaultValue={shouldSubSelectHasDefaultValue}
        />
      )}
    </>
  );
}
