import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { FormControlLabel, FormControlLabelProps, Switch } from "@mui/material";

export type SwitchWithReactHookFormProps<T extends FieldValues> = Omit<
  FormControlLabelProps,
  // FormControlLabel에 control가 겹쳐서 제외함
  "control" | "defaultValue"
> & {
  name: Path<T>;
  control?: Control<T>;
  defaultValue?: PathValue<T, (string | undefined) & Path<T>>;
};

export default function SwitchWithReactHookForm<T extends FieldValues>({
  name,
  control,
  defaultValue,
  ...props
}: SwitchWithReactHookFormProps<T>) {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          defaultValue={defaultValue}
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              checked={props.value ? !!props.value : !!field.value}
              onChange={field.onChange}
            />
          )}
        />
      }
      {...props}
    />
  );
}
