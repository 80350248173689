import { useCallback, useMemo, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { ADMIN_WITHDRAWAL_TEMPLATE_CATEGORY_LIST } from "@sellernote/_shared/src/constants/forwarding/adminTemplate";
import { TemplateCategory } from "@sellernote/_shared/src/types/forwarding/adminTemplate";
export default function useTemplateCategorySelect({
  defaultCategory,
}: {
  defaultCategory: TemplateCategory;
}) {
  const [templateCategory, setTemplateCategory] = useState(defaultCategory);

  const handleTemplateCategoryChange = useCallback(
    (event: SelectChangeEvent<TemplateCategory>) => {
      setTemplateCategory(event.target.value as TemplateCategory);
    },
    []
  );

  const TemplateCategorySelect = useMemo(() => {
    return (
      <FormControl sx={{ width: 200 }} size="small">
        <InputLabel>구분</InputLabel>

        <Select
          disabled={defaultCategory !== "apEtc"}
          value={templateCategory}
          onChange={handleTemplateCategoryChange}
        >
          {ADMIN_WITHDRAWAL_TEMPLATE_CATEGORY_LIST.map((v) => {
            return (
              <MenuItem key={v.value} value={v.value}>
                {v.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }, [defaultCategory, handleTemplateCategoryChange, templateCategory]);

  return {
    TemplateCategorySelect,
    templateCategory,
  };
}
