import styled from "styled-components";

const pdfWrapper = styled.div`
  .react-pdf__Page {
    display: inline-block;
  }
`;

export default {
  pdfWrapper,
};
