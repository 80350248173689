import { Chip, ChipProps } from "@mui/material";

import Styled from "./index.styles";

export default function ExtraSmallChip(props: ChipProps) {
  return (
    <Styled.container>
      <Chip {...props} sx={{ height: "18px", fontSize: "11px" }} />
    </Styled.container>
  );
}
