import { useCallback, useMemo, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { IconButton } from "@mui/material";

export default function useTableHeadSortFilter({
  sortTypeOptionList,
}: {
  sortTypeOptionList: string[];
}) {
  const [sort, setSort] = useState<string | undefined>(undefined);
  const [order, setOrder] = useState<string | undefined>(undefined);

  const getOrderValue = useCallback(() => {
    if (order === "ASC") {
      return "DESC";
    }

    if (order === "DESC") {
      return undefined;
    }

    return "ASC";
  }, [order]);

  const handleSortClick = useCallback(
    (sortType: string) => {
      if (sort === sortType) {
        setOrder(getOrderValue());
      } else {
        setSort(sortType);
        setOrder("ASC");
      }
    },
    [getOrderValue, sort]
  );

  const getArrowIcon = useCallback(
    (sortType: string) => {
      if (sort === sortType) {
        if (order === "ASC") {
          return <ArrowDropUpIcon color="primary" />;
        }

        if (order === "DESC") {
          return <ArrowDropDownIcon color="primary" />;
        }
      }
      return <ArrowRightIcon />;
    },
    [order, sort]
  );

  const sortFilterMap = useMemo(() => {
    return sortTypeOptionList.reduce((acc, cur) => {
      const Filter = (
        <IconButton size="small" onClick={() => handleSortClick(cur)}>
          {getArrowIcon(cur)}
        </IconButton>
      );

      acc.set(cur, Filter);

      return acc;
    }, new Map());
  }, [getArrowIcon, handleSortClick, sortTypeOptionList]);

  return {
    sortFilterMap,
    sort: order ? sort : undefined,
    order,
  };
}
