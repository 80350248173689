import { useContext } from "react";

import { SnackbarContext, SnackbarContextType } from "./SnackbarContext";

export default function useSnackbar(): SnackbarContextType {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error("useSnackbar should be used within SnackbarProvider");
  }

  return context;
}
