import { useCallback, useMemo, useState } from "react";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";

export type TableHeadFilterOption<T> = {
  label: string | number;
  value: T;
};

/**
 *
 * 기존 useTableHeadFilter와 다른 점
 * 기존 mouseover 이벤트로 하나의 완성된 모달로 작동하는 필터에서,
 * 백드랍 css 부분을 분리해서 배열을 보여주는 부분만 남겼습니다.
 * 한 칼럼 안에 2개나 그 이상의 필터를 설치해야 하는 상황에 unstyled 된 이 필터를
 * 사용해서 배열 여러개를 한 모달에 둘 때 사용할 수 있습니다.
 * 리셋 버튼도 hook으로 리턴할 수 있게 하고 있어 히스토리 저장에 유용합니다.
 */
function useTableHeadFilterUnstyled<T>({
  filterOptions,
  canSelectAllButton,
}: {
  filterOptions: TableHeadFilterOption<T>[];
  canSelectAllButton?: boolean;
}) {
  const [filter, setFilter] = useState<T[]>([]);

  const [draftFilter, setDraftFilter] = useState<T[]>(filter);

  const allOptions = filterOptions.map((option) => option.value);

  const isAllChecked = useMemo(
    () => draftFilter.length === allOptions.length,
    [draftFilter, allOptions]
  );

  const handleFilterReset = useCallback(
    (value = filter) => {
      setDraftFilter([...value]);
    },
    [setDraftFilter, filter]
  );

  const handleFilterCheckAll = useCallback(() => {
    if (isAllChecked) {
      setDraftFilter([]);
    }
    if (!isAllChecked) {
      setDraftFilter([...allOptions]);
    }
  }, [setDraftFilter, allOptions, isAllChecked]);

  const FilterPanel = useMemo(() => {
    return (
      <Grid item>
        <Box>
          {canSelectAllButton && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllChecked}
                  onClick={() => handleFilterCheckAll()}
                />
              }
              label={isAllChecked ? "전체 취소" : "전체 선택"}
            />
          )}

          {filterOptions.map((v) => {
            const filtered = draftFilter.includes(v.value);

            return (
              <Box className="item" key={v.label}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filtered}
                      onClick={(e) => {
                        e.stopPropagation();

                        if (filtered) {
                          setDraftFilter(
                            draftFilter.filter((fv) => fv !== v.value)
                          );
                        } else {
                          setDraftFilter([...draftFilter, v.value]);
                        }
                      }}
                      size="small"
                    />
                  }
                  label={v.label}
                />
              </Box>
            );
          })}
        </Box>
      </Grid>
    );
  }, [
    filterOptions,
    draftFilter,
    canSelectAllButton,
    isAllChecked,
    handleFilterCheckAll,
  ]);

  return {
    FilterPanel,
    setDraftFilter,
    draftFilter,
    setFilter,
    handleFilterReset,
  };
}

export default useTableHeadFilterUnstyled;
