import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from "@mui/material";
import { blue } from "@mui/material/colors";

type TextFieldProps = {
  /** 글자 하이라이트 추가 여부 */
  highlightsText?: boolean;
} & MUITextFieldProps;

function TextField({ highlightsText, sx, ...props }: TextFieldProps) {
  return (
    <MUITextField
      {...props}
      sx={{
        "& .MuiInputBase-input": {
          color: highlightsText ? blue[500] : "inherit",
          fontWeight: highlightsText ? "bold" : "inherit",
        },
        /** label이 없는 경우 top border line이 끊기는 문제가 있음 이를 채워주는 스타일을 추가 */
        ...(!props.label && {
          "& .MuiOutlinedInput-root": {
            "& legend": { display: "none" },
            "& fieldset": {
              top: 0,
            },
          },
        }),
        ...sx,
      }}
    />
  );
}

export default TextField;
