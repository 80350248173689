import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { TextField, TextFieldProps } from "@mui/material";

export type NumericFormatWithReactHookFormProps<T extends FieldValues> = {
  name: Path<T>;
  control?: Control<T>;
  required?: boolean;
  pattern?: RegExp;
  errorMessage?: string;
  defaultValue?: PathValue<T, (string | undefined) & Path<T>>;
  handleEffectOnChange?: () => void;
};

export default function NumericFormatWithReactHookForm<
  TFieldValues extends FieldValues
>({
  name,
  control,
  required,
  pattern,
  errorMessage,
  defaultValue,
  handleEffectOnChange,
  ...props
}: TextFieldProps &
  NumericFormatProps &
  NumericFormatWithReactHookFormProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        required,
        pattern,
      }}
      render={({ field, fieldState: { error } }) => (
        <NumericFormat
          {...props}
          getInputRef={field.ref}
          value={props.value ? props.value : field.value ?? null}
          customInput={TextField}
          variant={props.variant ?? "standard"}
          error={error !== undefined}
          helperText={
            error &&
            (error.type === "required" ? "필수 입력 사항입니다." : errorMessage)
          }
          onValueChange={(value) => {
            /** 인풋 값 제거 시 value.floatValue는 undefined가 나온다 null이 나올 수 있도록 수정 */
            field.onChange(value?.floatValue ? value.floatValue : null);
            handleEffectOnChange && handleEffectOnChange();
          }}
          disabled={props.disabled}
        />
      )}
    />
  );
}
