import {
  Control,
  Controller,
  FieldPathValue,
  FieldValues,
  Path,
  PathValue,
  Validate,
} from "react-hook-form";
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";

export type AutoCompleteWithReactHookFormProps<
  T extends FieldValues,
  K extends string | { label: string; value: string | number }
> = Omit<
  AutocompleteProps<
    K,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >,
  // renderInput을 받는 것보다 안에서 사용하는게 더 좋을 것 같다고 판단 사용 후 다른 의견 있을 시 수정
  "renderInput"
> & {
  label?: string;
  name: Path<T>;
  control?: Control<T>;
  options: K[];
  defaultValue?: PathValue<
    T,
    (string | { label: string; value: string | number } | undefined) & Path<T>
  >;
  required?: boolean;
  disabled?: boolean;
  validate?:
    | Validate<FieldPathValue<T, Path<T>>>
    | Record<string, Validate<FieldPathValue<T, Path<T>>>>;
  handleEffectOnChange?: (
    data: K | NonNullable<string | K> | (string | K)[] | null
  ) => void;
};

export default function AutoCompleteWithReactHookForm<
  TFieldValues extends FieldValues,
  K extends string | { label: string; value: string | number }
>({
  label,
  name,
  options,
  control,
  defaultValue,
  disabled,
  required,
  validate,
  handleEffectOnChange,
  ...props
}: AutoCompleteWithReactHookFormProps<TFieldValues, K>) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required,
          validate,
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <Autocomplete
              {...props}
              {...field}
              disabled={disabled}
              value={props.value ? props.value : field.value ?? null}
              onChange={(_, data) => {
                if (data && typeof data === "object" && "value" in data) {
                  field.onChange(data.value);
                  handleEffectOnChange && handleEffectOnChange(data);
                } else {
                  field.onChange(data);
                  handleEffectOnChange && handleEffectOnChange(data);
                }
              }}
              getOptionLabel={(option) => {
                /** 선택한 값이 label을 가지고 있는 객체인 경우 label를 리턴 */
                if (typeof option === "object" && "label" in option) {
                  return option.label;
                }

                /** label을 가지지 않는 경우 options 안에 있는 리스트이 value값과 일치하는 옵션을 찾는다. */
                const foundOption = options.find((optionItem) => {
                  if (typeof optionItem === "object" && "value" in optionItem) {
                    return optionItem.value === option;
                  }
                  return optionItem === option;
                });

                /** 찾은 옵션의 라벨이 있는 경우 라벨을 리턴 */
                if (typeof foundOption === "object" && "label" in foundOption) {
                  return foundOption.label;
                }

                return String(option);
              }}
              isOptionEqualToValue={(option, value) => {
                /** 옵션과 벨류가 모두 객체인 경우 */
                if (typeof option === "object" && typeof value === "object") {
                  return option.value === value.value;
                }

                /** 옵션이 객체이고 value는 string 또는 number인 경우 */
                if (
                  typeof option === "object" &&
                  "value" in option &&
                  (typeof value === "string" || typeof value === "number")
                ) {
                  return option.value === value;
                }

                return option === value;
              }}
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={label}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleEffectOnChange &&
                      handleEffectOnChange(e.target.value);
                  }}
                  error={error !== undefined}
                  helperText={
                    error &&
                    (error.type === "required"
                      ? "필수 입력 사항입니다."
                      : error.message)
                  }
                />
              )}
            />
          );
        }}
      />
    </>
  );
}
