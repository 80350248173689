import { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import DatePicker from "../../components/DatePicker";

export type SearchWithDateTypeOption<T extends string | number> = {
  label: string;
  value: T;
};

export default function useSearchWithDate<T extends string | number>({
  dateSearchTypeOptions,
  startLabel,
  endLabel,
  historyStartDate,
  historyEndDate,
  resetCurrentPage,
}: {
  dateSearchTypeOptions: SearchWithDateTypeOption<T>[];
  startLabel?: string;
  endLabel?: string;
  historyStartDate?: string | null;
  historyEndDate?: string | null;
  resetCurrentPage?: () => void;
}) {
  const [dateSearchType, setDateSearchType] = useState<T>(
    dateSearchTypeOptions[0].value
  );
  const [startDate, setStartDate] = useState<string | null>(
    historyStartDate ?? null
  );
  const [endDate, setEndDate] = useState<string | null>(historyEndDate ?? null);

  const handleDateReset = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    resetCurrentPage && resetCurrentPage();
  }, [resetCurrentPage]);

  const DateSearchPanel = useMemo(() => {
    return (
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <FormControl className="date-search-type" variant="outlined">
          <InputLabel id="date-search-type-select-label">날짜 타입</InputLabel>

          <Select
            labelId="date-search-type-select-label"
            id="date-search-type-select"
            value={dateSearchType}
            label="날짜 타입"
            defaultValue={dateSearchType}
            onChange={(e) => {
              setDateSearchType(e.target.value as T);
            }}
            size="small"
          >
            {dateSearchTypeOptions.map((st, i) => (
              <MenuItem value={st.value} key={i}>
                {st.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <DatePicker
          label={startLabel || "시작일"}
          when="start"
          value={startDate}
          setDate={setStartDate}
        />

        <DatePicker
          label={endLabel || "종료일"}
          when="end"
          value={endDate}
          setDate={(v) => {
            setEndDate(v);
            resetCurrentPage && resetCurrentPage();
          }}
        />

        {startDate && endDate && (
          <Button
            variant="contained"
            sx={{
              cursor: "pointer",
              height: "40px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleDateReset();
            }}
          >
            초기화
          </Button>
        )}
      </Box>
    );
  }, [
    dateSearchType,
    dateSearchTypeOptions,
    startLabel,
    startDate,
    endLabel,
    endDate,
    resetCurrentPage,
    handleDateReset,
  ]);

  return {
    DateSearchPanel,
    dateSearchType,
    startDate,
    endDate,
    setStartDate,
    handleDateReset,
  };
}
