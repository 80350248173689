import styled from "styled-components";

// 라벨 사이즈 수정을 위해 추가.
const container = styled.div`
  > .MuiChip-root > .MuiChip-label {
    padding: 2px 7px 0 7px;
    line-height: 16px;
  }
`;

export default {
  container,
};
