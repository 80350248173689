import { Box, Typography } from "@mui/material";

import {
  TeamBid,
  TeamSearchLog,
} from "@sellernote/_shared/src/types/forwarding/adminTeam";
import { getSupplyValueInUnit } from "@sellernote/_shared/src/utils/forwarding/bid";

function CargoInfo({ infoData }: { infoData: TeamBid | TeamSearchLog }) {
  return (
    <Box>
      <Typography variant="body2" component="div">
        {infoData.name.length > 1
          ? `${infoData.name[0]} 외 ${infoData.name.length - 1}`
          : infoData.name[0]}
      </Typography>

      <Typography variant="body2" component="div">
        {infoData.freightType}
      </Typography>

      {infoData.supply && (
        <Typography variant="body2" component="div">
          {getSupplyValueInUnit(infoData.freightType, infoData.supply)}
        </Typography>
      )}
    </Box>
  );
}

export default CargoInfo;
