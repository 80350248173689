import React, { useCallback, useEffect, useRef } from "react";
import { CloudUpload, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";

import {
  FileExtension,
  FileTypes,
} from "@sellernote/_shared/src/types/common/upload";

const checkFileSize = (allowedFileSize: number | undefined, file: FileType) =>
  allowedFileSize ? file.fileInfo.size <= allowedFileSize : true;

type FileType = FileTypes & { url?: string };

export default function FileUpload({
  files,
  setFiles,
  isMultiple,
  acceptedFileTypes,
  handleUpload,
  warningMessage,
  showsFileExtensionMessage,
  allowedFileSize,
}: {
  files: FileTypes[];
  setFiles: React.Dispatch<React.SetStateAction<FileTypes[]>>;
  isMultiple: boolean;
  acceptedFileTypes?: FileExtension[];
  handleUpload?: () => void;
  warningMessage?: string;
  showsFileExtensionMessage?: boolean;
  allowedFileSize?: number;
}) {
  const fileId = useRef<number>(0);

  const hasError = !!files.find((file) => {
    if (!allowedFileSize) {
      return false;
    }
    const isAllowedFileSize = checkFileSize(allowedFileSize, file);

    return !isAllowedFileSize;
  });

  const selectFile = useCallback(
    (e) => {
      let selectFiles: File[] = [];
      let tempFiles: FileTypes[] = files;

      if (e.type === "drop") {
        selectFiles = e.dataTransfer.files;
      } else {
        selectFiles = e.target.files;
      }

      for (const file of selectFiles) {
        tempFiles = [
          ...tempFiles,
          {
            id: fileId.current++,
            fileInfo: file,
          },
        ];
      }

      setFiles(tempFiles);
    },
    [files, setFiles]
  );

  const deselectFile = useCallback(
    (id: number) => {
      return () => {
        setFiles(files.filter((file) => file.id !== id));
      };
    },
    [files, setFiles]
  );

  const dragRef = useRef<HTMLLabelElement | null>(null);

  const handleDragIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOut = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      selectFile(e);
    },
    [selectFile]
  );

  const initDragEvents = useCallback(() => {
    if (!dragRef) return;
    if (dragRef.current) {
      dragRef.current.addEventListener("dragenter", handleDragIn);
      dragRef.current.addEventListener("dragleave", handleDragOut);
      dragRef.current.addEventListener("dragover", handleDragOver);
      dragRef.current.addEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const resetDragEvents = useCallback(() => {
    if (!dragRef) return;
    if (dragRef.current) {
      dragRef.current.removeEventListener("dragenter", handleDragIn);
      dragRef.current.removeEventListener("dragleave", handleDragOut);
      dragRef.current.removeEventListener("dragover", handleDragOver);
      dragRef.current.removeEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  useEffect(() => {
    initDragEvents();

    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents]);

  return (
    <Container>
      <Typography
        variant="h5"
        sx={{ color: blue[700], marginBottom: "16px", textAlign: "center" }}
        component="div"
      >
        파일 업로드
      </Typography>

      <input
        id="file-upload-drag"
        type="file"
        hidden
        multiple={isMultiple}
        accept={
          acceptedFileTypes
            ?.map((v) => {
              return "." + v;
            })
            .join() || "*"
        }
        onChange={selectFile}
      />

      <label htmlFor="file-upload-drag" ref={dragRef}>
        <Box
          ref={dragRef}
          sx={{
            border: "1px dashed #1976d2",
            borderRadius: "8px",
            textAlign: "center",
          }}
          component="div"
        >
          <CloudUpload color="primary" sx={{ fontSize: 50 }} />

          <Box
            sx={{
              backgroundColor: blue[50],
              padding: "10px",
              minHeight: "100px",
            }}
          >
            <Typography variant="h6" component="div">
              클릭 또는 드래그 앤 드랍으로 업로드 할 수 있습니다.
            </Typography>

            <Typography variant="subtitle1" component="div">
              {isMultiple
                ? "한개 또는 다수의 파일을 동시에 업로드 할 수 있습니다."
                : "한개의 파일만 업로드 할 수 있습니다."}
            </Typography>

            {showsFileExtensionMessage && (
              <>
                <Typography variant="body2" component="div">
                  -업로드 지원 확장자-
                </Typography>

                <Typography variant="body2" component="div">
                  {acceptedFileTypes?.join()}
                </Typography>

                <Typography variant="body2" component="div">
                  {warningMessage}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </label>

      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <List>
          {files &&
            files.map((v) => {
              const isAllowedFileSize = checkFileSize(allowedFileSize, v);

              return (
                <ListItem
                  key={v.id}
                  disablePadding
                  secondaryAction={
                    <IconButton
                      onClick={deselectFile(v.id)}
                      edge="end"
                      aria-label="delete"
                    >
                      <Delete />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={v.fileInfo.name}
                    secondary={
                      !isAllowedFileSize && (
                        <Typography color="error">
                          용량이 큽니다. 삭제 후 재업로드 해주세요.
                        </Typography>
                      )
                    }
                  />
                </ListItem>
              );
            })}
        </List>

        {handleUpload && (
          <Button
            variant="contained"
            disabled={files.length === 0 || hasError}
            onClick={handleUpload}
          >
            업로드
          </Button>
        )}
      </Box>
    </Container>
  );
}
