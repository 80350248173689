import styled from "styled-components";

const container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  > div {
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(2) {
      &::before {
        content: "~";
        padding: 3px;
        margin-right: 4px;
      }
    }
  }
`;

const datePicker = styled.div`
  position: relative;

  > .calendar {
    position: absolute;
    z-index: 9;
    top: 40px;
    left: 10;

    &.hidden {
      visibility: hidden;
    }
  }

  > .clear {
    background-color: #fff;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -8px;
  }
`;

const dateSearchPanelWrapper = styled.div`
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
`;

const dateSearchPanel = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  background-color: transparent;
  border-radius: 8px;

  > div {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
`;

export default {
  container,
  datePicker,
  dateSearchPanelWrapper,
  dateSearchPanel,
};
