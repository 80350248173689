import { useCallback, useMemo, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

export interface SelectFilterOptionList<T extends string | number | undefined> {
  label: string | number;
  value: T;
}

export default function useSelectFilter<T extends string | number | undefined>({
  selectFilterOptionList,
  label,
  width,
  defaultValue,
  setHistoryData,
}: {
  selectFilterOptionList: SelectFilterOptionList<T>[];
  label?: string;
  width?: string;
  defaultValue?: T;
  setHistoryData?: (value: T) => void;
}) {
  const [selectFilter, setSelectFilter] = useState<
    SelectFilterOptionList<T> | undefined
  >(
    defaultValue
      ? selectFilterOptionList.find((v) => {
          return v.value === defaultValue;
        })
      : selectFilterOptionList[0]
  );

  const handleSelectFilterChange = useCallback(
    (event: SelectChangeEvent) => {
      const target = selectFilterOptionList.find(
        (st) => st.value === event.target.value
      );

      if (target) {
        setSelectFilter(target);
        setHistoryData && setHistoryData(target.value);
      }
    },
    [selectFilterOptionList, setHistoryData]
  );

  const SelectFilterPanel = useMemo(() => {
    return (
      <FormControl variant="outlined" sx={{ display: "flex" }}>
        <InputLabel id="select-filter-label">{label ?? ""}</InputLabel>

        <Select
          labelId="select-filter-label"
          id="select-filter"
          // value의 타입은 string | undefined여야 하는데 value가 number인 옵션 리스트를 넣기 위해서 임의로 처리
          value={`${selectFilter?.value}` || undefined}
          label={label}
          onChange={handleSelectFilterChange}
          size="small"
          sx={{ width: width ?? "150px" }}
        >
          {selectFilterOptionList.map((st, i) => (
            <MenuItem value={st.value} key={i}>
              {st.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [
    label,
    selectFilter?.value,
    handleSelectFilterChange,
    width,
    selectFilterOptionList,
  ]);

  return {
    SelectFilterPanel,
    selectFilter,
  };
}
