import { ChangeEvent } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import { red } from "@mui/material/colors";

export type RadioGroupWithReactHookFormOption<
  K extends string | number | boolean | undefined
> = {
  label: string;
  value: K;
  disabled?: boolean;
};

export type RadioGroupWithReactHookFormProps<
  T extends FieldValues,
  K extends string | number | boolean | undefined
> = {
  name: Path<T>;
  radioGroupOption: RadioGroupWithReactHookFormOption<K>[];
  control?: Control<T>;
  required?: boolean;
  defaultValue?: PathValue<
    T,
    (string | number | boolean | undefined) & Path<T>
  >;
  disabled?: boolean;
  handleEffectOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function RadioGroupWithReactHookForm<
  T extends FieldValues,
  K extends string | number | boolean | undefined
>({
  name,
  radioGroupOption,
  control,
  required,
  defaultValue,
  disabled,
  handleEffectOnChange,
}: RadioGroupWithReactHookFormProps<T, K>) {
  return (
    <Controller
      rules={{ required }}
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <RadioGroup {...field} row value={field.value}>
            {radioGroupOption.map((radioGroupItem) => {
              return (
                <FormControlLabel
                  disabled={disabled}
                  key={radioGroupItem.label}
                  value={radioGroupItem.value}
                  control={
                    <Radio
                      disabled={radioGroupItem.disabled}
                      onChange={(event) => {
                        field.onChange(event);
                        handleEffectOnChange && handleEffectOnChange(event);
                      }}
                    />
                  }
                  label={radioGroupItem.label}
                />
              );
            })}
          </RadioGroup>

          {error && error.type === "required" && (
            <FormHelperText sx={{ color: red[500] }}>
              "필수 입력 사항입니다."
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
