import { Chip, Grid, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import {
  FreightType,
  OriginInlandType,
} from "@sellernote/_shared/src/types/common/common";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import { changeInlandTypeToNameKr } from "@sellernote/_shared/src/utils/forwarding/trello";

function ExportCheckPointAndAddition({
  containerStuffing,
  originInland,
  useVGM,
  containCustoms,
  containExportCustoms,
  hopeCargoInsurance,
  freightType,
}: {
  containerStuffing: boolean;
  /** 수출 운송방식 */
  originInland: OriginInlandType | null;
  /** VGM */
  useVGM: boolean;
  /** 수입통관 신청 여부 */
  containCustoms: boolean;
  /** 수출통관 신청 여부 */
  containExportCustoms: boolean;
  hopeCargoInsurance: boolean;
  freightType: FreightType;
}) {
  return (
    <Grid container spacing={2} sx={{ padding: "50px" }}>
      <Grid item xs={12}>
        <Chip color="primary" label="체크포인트" variant="outlined" />
      </Grid>

      <Grid item container spacing={4}>
        {freightType === "FCL" && (
          <Grid item>
            <Typography
              sx={{ color: blue[800] }}
              component="div"
              variant="subtitle1"
            >
              출발지 컨테이너 진입가능
            </Typography>

            <Typography component="div" variant="body2">
              {changeBooleanValueToKr(containerStuffing)}
            </Typography>
          </Grid>
        )}

        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            운송방식
          </Typography>

          <Typography component="div" variant="body2">
            {/* 수출 FCL인 경우에는 독차고정 */}
            {freightType === "FCL"
              ? "독차"
              : changeInlandTypeToNameKr(originInland)}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Chip color="primary" label="부가서비스" variant="outlined" />
      </Grid>

      <Grid item container spacing={4}>
        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            수출통관
          </Typography>

          <Typography component="div" variant="body2">
            {containExportCustoms ? "예" : "아니오"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            수입통관
          </Typography>

          <Typography component="div" variant="body2">
            {containCustoms ? "예" : "아니오"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            적하보험
          </Typography>

          <Typography component="div" variant="body2">
            {hopeCargoInsurance ? "예" : "아니오"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            sx={{ color: blue[800] }}
            component="div"
            variant="subtitle1"
          >
            VGM
          </Typography>

          <Typography component="div" variant="body2">
            {useVGM ? "예" : "아니오"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ExportCheckPointAndAddition;
