import styled from "styled-components";

const filterPanel = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const filterDetail = styled.div`
  position: fixed;
  top: 24px;
  right: 6px;
  min-width: 200px;
  z-index: 1400;

  .list {
    max-height: 250px;
    overflow-y: scroll;
  }

  .clear {
    position: absolute;
    right: 10px;
    top: 6px;
    cursor: pointer;
  }

  .action {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > * {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`;

export default {
  filterPanel,
  filterDetail,
};
