import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { JoinedAllTeamItem } from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { TeamAndUserDetail } from "@sellernote/_shared/src/types/forwarding/adminBidUser";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../LegacyTable";

type CellId = keyof JoinedAllTeamItem | "masterName";

function TeamList({ detailData }: { detailData: TeamAndUserDetail }) {
  const history = useHistory();

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "name",
        disablePadding: false,
        label: "팀명",
      },
      {
        id: "corpSizeType",
        disablePadding: false,
        label: "세그먼트",
      },
      {
        id: "masterName",
        disablePadding: false,
        label: "마스터 이름",
      },
      {
        id: "company",
        disablePadding: false,
        label: "회사명(국문)",
      },
      {
        id: "engCompany",
        disablePadding: false,
        label: "회사명(영문)",
      },
      {
        id: "BRN",
        disablePadding: false,
        label: "사업자등록번호",
      },
      {
        id: "memberCnt",
        disablePadding: false,
        label: "멤버 수",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!detailData?.joinedAllTeam) return [];

    return detailData?.joinedAllTeam.map((v) => {
      const row: LegacyTableBodyRow<CellId> = {
        name: v.name,
        corpSizeType: v.corpSizeType,
        masterName: v.masterName,
        company: v.company,
        engCompany: v.engCompany,
        BRN: v.BRN,
        memberCnt: v.memberCnt,
        handleRowClick: () => {
          history.push(`/team/${v.id}`);
        },
      };

      return row;
    });
  }, [detailData?.joinedAllTeam, history]);

  return <LegacyTable title={"팀 리스트"} headCells={headCells} rows={rows} />;
}

export default TeamList;
