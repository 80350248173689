import { Chip, Grid } from "@mui/material";

import { AdminShipmentItemGroup } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";

import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../../components/SADSTable";

type CellKey = "NO" | "품명" | "컨테이너" | "위험물" | "CBM" | "중량";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "NO", label: "NO", align: "right", width: 70 },
  { cellKey: "품명", label: "품명", width: 200 },
  { cellKey: "컨테이너", label: "컨테이너", align: "right", width: 100 },
  { cellKey: "CBM", label: "CBM", align: "right", width: 100 },
  { cellKey: "중량", label: "중량", align: "right", width: 100 },
  { cellKey: "위험물", label: "위험물", align: "right", width: 100 },
];

export default function ItemListForFCL({
  itemGroupList,
}: {
  itemGroupList: AdminShipmentItemGroup[];
}) {
  const bodyRowList: TableBodyRow<CellKey>[] = (itemGroupList || []).map(
    ({ item, containerInfo }, i) => {
      return {
        rowKey: item.id,
        NO: { value: item.id },
        품명: { value: item.name },
        컨테이너: {
          value: (
            <Grid container direction={"column"}>
              {containerInfo?.map((v, i) => (
                <Grid item key={i}>
                  {v.containerType} X {v.quantity}
                </Grid>
              ))}
            </Grid>
          ),
        },
        CBM: { value: item.cbm || "-" },
        중량: { value: item.weight || "-" },
        위험물: {
          value: changeBooleanValueToKr(item.isDangerous),
        },
      };
    }
  );

  return (
    <Grid container spacing={2} sx={{ padding: "50px" }}>
      <Grid item xs={12}>
        <Chip color="primary" label="컨테이너 상세정보" variant="outlined" />
      </Grid>

      <Grid item xs={7}>
        <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
      </Grid>
    </Grid>
  );
}
