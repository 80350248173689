import { useCallback, useMemo, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import DatePicker from "@sellernote/_shared-for-admin/src/hooks/common/useSearchWithDate/DatePicker";

import Styled from "./index.styles";

export type SearchWithDateTypeOption<T extends string | number> = {
  label: string;
  value: T;
};

/**
 *
 * 기존 useSearchWithDate와 다른 점
 * 내부에 useEffect를 없애서 새로고침시 값을 ""로 바꾸는 걸 방지하고
 * endDate가 startDate에 종속되지 않게 하고
 * defaultValue를 설정할 수 있게 setState를 hook 밖으로 리턴해주고 있습니다.
 * style도 약간 우측 정렬로 바뀌었습니다.
 * history나 로컬에 검색 내역을 저장하고 싶을때 useSearchWithDate 대신 쓸 수 있습니다.
 */
export default function useSearchWithDateWithNoUseEffect<
  T extends string | number
>({
  dateSearchTypeOptions,
  startLabel,
  endLabel,
  key = "adminBidFilter",
  defaultValue,
  dateHistory,
}: {
  dateSearchTypeOptions: SearchWithDateTypeOption<T>[];
  startLabel?: string;
  endLabel?: string;
  key?: string;
  defaultValue?: {
    startDate: string;
    endDate: string;
  };
  dateHistory?: {
    startDate: string | undefined;
    endDate: string | undefined;
    history?: { itemKey: string; itemKeyIndex: number };
  };
}) {
  const [dateSearchType, setDateSearchType] = useState<T>(
    dateSearchTypeOptions[dateHistory?.history?.itemKeyIndex ?? 0].value
  );

  const [startDate, setStartDate] = useState(
    dateHistory?.startDate
      ? dateHistory?.startDate
      : defaultValue?.startDate
      ? defaultValue?.startDate
      : ""
  );

  const [endDate, setEndDate] = useState(
    dateHistory?.endDate
      ? dateHistory?.endDate
      : defaultValue?.endDate
      ? defaultValue?.endDate
      : ""
  );

  const hasValue = useMemo(() => {
    return !!startDate || !!endDate;
  }, [startDate, endDate]);

  const SearchDate = useCallback(() => {
    return (
      <Styled.container>
        <DatePicker
          label={startLabel || "시작일"}
          when="start"
          date={startDate as string}
          setDate={setStartDate}
        />

        <DatePicker
          label={endLabel || "종료일"}
          when="end"
          date={endDate as string}
          setDate={(v) => {
            if (startDate > v) {
              setEndDate(startDate);
              return;
            }

            if (!startDate) {
              setStartDate(v);
              setEndDate(v);
              return;
            }

            setEndDate(v);
            return;
          }}
          {...(hasValue
            ? {
                reset: () => {
                  setStartDate("");
                  setEndDate("");
                },
              }
            : {})}
        />
      </Styled.container>
    );
  }, [startDate, endDate, hasValue, endLabel, startLabel]);

  const SelectForSearchType = useCallback(() => {
    return (
      <FormControl className="date-search-type" variant="outlined">
        <InputLabel id="date-search-type-select-label">날짜 타입</InputLabel>

        <Select
          sx={{ minWidth: 80 }}
          labelId="date-search-type-select-label"
          id="date-search-type-select"
          value={dateSearchType}
          label="날짜 타입"
          defaultValue={dateSearchType}
          onChange={(e) => {
            setDateSearchType(e.target.value as T);
            setStartDate("");
            setEndDate("");
          }}
          size="small"
        >
          {dateSearchTypeOptions.map((st, i) => (
            <MenuItem value={st.value} key={i}>
              {st.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [dateSearchType, dateSearchTypeOptions]);

  const DateSearchPanel = useCallback(
    ({ style }: { style?: React.CSSProperties }) => {
      return (
        <Styled.dateSearchPanelWrapper style={style}>
          <Styled.dateSearchPanel>
            <SelectForSearchType />

            <SearchDate />
          </Styled.dateSearchPanel>
        </Styled.dateSearchPanelWrapper>
      );
    },
    [SelectForSearchType, SearchDate]
  );

  return {
    DateSearchPanel,
    dateSearchType,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  };
}
