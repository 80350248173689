import { Chip, Grid } from "@mui/material";

import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";

import useExchangeRateData from "./useExchangeRateData";

function ExchangeRateInfo({
  exchangeRateList,
}: {
  exchangeRateList: ExchangeRate[];
}) {
  const { usdRate, eurRate, cnyRate, gbpRate } = useExchangeRateData({
    exchangeRateList,
  });

  return (
    <Grid container sx={{ padding: "50px" }}>
      <Grid item container xs={2}>
        <Grid item>
          <Chip
            color="primary"
            label={`원-달러 환율 ${usdRate}`}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid item container xs={2}>
        <Grid item>
          <Chip
            color="primary"
            label={`원-유로 환율 ${eurRate}`}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid item container xs={2}>
        <Grid item>
          <Chip
            color="primary"
            label={`원-위안 환율 ${cnyRate}`}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid item container xs={2}>
        <Grid item>
          <Chip
            color="primary"
            label={`원-파운드 환율 ${gbpRate}`}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ExchangeRateInfo;
