import { useMemo, useState } from "react";

import { Port } from "@sellernote/_shared/src/types/common/common";
import { TeamAndUserDetail } from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { TeamSearchLog } from "@sellernote/_shared/src/types/forwarding/adminTeam";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../LegacyTable";
import CargoInfo from "../_components/CargoInfo";
import CheckPointInfo from "../_components/CheckPointInfo";
import ShippingInfo from "../_components/ShippingInfo";

type CellId = keyof TeamSearchLog | "cargoInfo" | "shippingInfo" | "checkpoint";

function SearchLogTable({
  detailData,
  portList,
}: {
  detailData: TeamAndUserDetail;
  portList: Port[];
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const HeadCells = useMemo(() => {
    const headCells: LegacyTableHeadCell<CellId>[] = [
      {
        id: "userName",
        disablePadding: false,
        label: "담당자명",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "검색 일시",
      },
      {
        id: "cargoInfo",
        disablePadding: false,
        label: "화물정보",
      },
      {
        id: "shippingInfo",
        disablePadding: false,
        label: "운송정보",
      },
      {
        id: "checkpoint",
        disablePadding: false,
        label: "체크포인트",
      },
    ];
    return headCells;
  }, []);

  const rows = useMemo(() => {
    if (!detailData.searchLogs) return [];

    return (
      detailData.searchLogs
        // API 요청이 없어서 자체 페이지네이션을 한다.
        .slice(currentPage * perPage, currentPage * perPage + perPage)
        .map((searchLog) => {
          const row: LegacyTableBodyRow<CellId> = {
            userName: searchLog.userName || detailData.userName,
            createdAt: toFormattedDate(
              searchLog.createdAt,
              "YYYY.MM.DD a hh:mm"
            ),
            cargoInfo: <CargoInfo infoData={searchLog} />,
            shippingInfo: (
              <ShippingInfo infoData={searchLog} portList={portList} />
            ),
            checkpoint: <CheckPointInfo infoData={searchLog} />,
          };

          return row;
        })
    );
  }, [
    detailData.searchLogs,
    detailData.userName,
    currentPage,
    perPage,
    portList,
  ]);

  return (
    <LegacyTable
      title={"검색기록"}
      headCells={HeadCells}
      rows={rows}
      pagination={{
        rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
        totalCount: detailData?.searchLogs?.length || 0,
        perPage,
        setPerPage,
        currentPage,
        setCurrentPage,
      }}
    />
  );
}

export default SearchLogTable;
