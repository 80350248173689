import { Box, Grid, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { getSupplyValueInUnit } from "@sellernote/_shared/src/utils/forwarding/bid";

function SummaryHeader({
  freightType,
  supply,
  id,
}: {
  freightType: FreightType;
  supply: number;
  id?: number;
}) {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "10px",
        backgroundColor: blue[500],
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        color: "#ffffff",
      }}
    >
      <Grid container>
        <Grid xs={10} item container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="subtitle1" component="span">
              운송모드
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h6" component="span">
              {freightType}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h6" component="span">
              |
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="subtitle1" component="span">
              총 물동량
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h6" component="span">
              {getSupplyValueInUnit(freightType, supply)}
            </Typography>
          </Grid>
        </Grid>

        <Grid xs={2} item container spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="subtitle1" component="span">
              의뢰번호
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h6" component="span">
              {id}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SummaryHeader;
