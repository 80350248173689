import { useRecoilValue, useSetRecoilState } from "recoil";

import { FORWARDING_ADMIN_BID_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBid";
import { FORWARDING_ADMIN_TRELLO_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminTrello";
import { TemplateCategory } from "@sellernote/_shared/src/types/forwarding/adminTemplate";

// 템플릿 데이터를 불러왔는지를 전역을 확인
export default function useCheckTemplateData() {
  const checkIsWithdrawalTemplate = (templateCategory: TemplateCategory) => {
    if (
      templateCategory === "shipping" ||
      templateCategory === "foreign" ||
      templateCategory === "domestic" ||
      templateCategory === "warehouse" ||
      templateCategory === "inland" ||
      templateCategory === "apEtc" ||
      templateCategory === "customs" ||
      templateCategory === "customsDuty"
    ) {
      return true;
    }
    return false;
  };

  const isLoadedAllTemplateData = useRecoilValue(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_ALL_TEMPLATE_DATA
  );

  const isLoadedFreightTemplateData = useRecoilValue(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_FREIGHT_TEMPLATE_DATA
  );

  const isLoadedLocalTemplateData = useRecoilValue(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_LOCAL_TEMPLATE_DATA
  );

  const isLoadedDomesticTemplateData = useRecoilValue(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_DOMESTIC_TEMPLATE_DATA
  );

  const isLoadedInlandTemplateData = useRecoilValue(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_INLAND_TEMPLATE_DATA
  );

  const isLoadedOtherTemplateData = useRecoilValue(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_OTHER_TEMPLATE_DATA
  );

  const isLoadedTaxTemplateData = useRecoilValue(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_TAX_TEMPLATE_DATA
  );

  const isLoadedWithdrawalTemplateData = useRecoilValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.IS_LOADED_WITHDRAWAL_TEMPLATE_DATA
  );

  const setIsLoadAllTemplateData = useSetRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_ALL_TEMPLATE_DATA
  );

  const setIsLoadFreightTemplateData = useSetRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_FREIGHT_TEMPLATE_DATA
  );

  const setIsLoadLocalTemplateData = useSetRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_LOCAL_TEMPLATE_DATA
  );

  const setIsLoadDomesticTemplateData = useSetRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_DOMESTIC_TEMPLATE_DATA
  );

  const setIsLoadInlandTemplateData = useSetRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_INLAND_TEMPLATE_DATA
  );

  const setIsLoadOtherTemplateData = useSetRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_OTHER_TEMPLATE_DATA
  );

  const setIsLoadTaxTemplateData = useSetRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.IS_LOADED_TAX_TEMPLATE_DATA
  );

  const setIsLoadedWithdrawalTemplateData = useSetRecoilState(
    FORWARDING_ADMIN_TRELLO_ATOMS.IS_LOADED_WITHDRAWAL_TEMPLATE_DATA
  );

  const getSaveStatus = (templateCategory: TemplateCategory) => {
    if (templateCategory === "tax") {
      return isLoadedTaxTemplateData ? "overwrite" : "new";
    }

    if (templateCategory === "freightCost") {
      return isLoadedFreightTemplateData ? "overwrite" : "new";
    }

    if (templateCategory === "domesticCost") {
      return isLoadedDomesticTemplateData ? "overwrite" : "new";
    }

    if (templateCategory === "localCost") {
      return isLoadedLocalTemplateData ? "overwrite" : "new";
    }

    if (templateCategory === "inlandCost") {
      return isLoadedInlandTemplateData ? "overwrite" : "new";
    }

    if (templateCategory === "otherCost") {
      return isLoadedOtherTemplateData ? "overwrite" : "new";
    }

    if (checkIsWithdrawalTemplate(templateCategory)) {
      return isLoadedWithdrawalTemplateData ? "overwrite" : "new";
    }

    return isLoadedAllTemplateData ? "overwrite" : "new";
  };

  const setIsLoadTemplateData = (
    templateCategory: TemplateCategory,
    value: boolean
  ) => {
    if (templateCategory === "tax") {
      return setIsLoadTaxTemplateData(value);
    }

    if (templateCategory === "freightCost") {
      return setIsLoadFreightTemplateData(value);
    }

    if (templateCategory === "domesticCost") {
      return setIsLoadDomesticTemplateData(value);
    }

    if (templateCategory === "localCost") {
      return setIsLoadLocalTemplateData(value);
    }

    if (templateCategory === "inlandCost") {
      return setIsLoadInlandTemplateData(value);
    }

    if (templateCategory === "otherCost") {
      return setIsLoadOtherTemplateData(value);
    }

    if (checkIsWithdrawalTemplate(templateCategory)) {
      return setIsLoadedWithdrawalTemplateData(value);
    }

    return setIsLoadAllTemplateData(value);
  };

  const changeIsLoadTemplateToFalseExceptAll = () => {
    setIsLoadTaxTemplateData(false);
    setIsLoadFreightTemplateData(false);
    setIsLoadDomesticTemplateData(false);
    setIsLoadLocalTemplateData(false);
    setIsLoadInlandTemplateData(false);
    setIsLoadOtherTemplateData(false);
    setIsLoadedWithdrawalTemplateData(false);

    return;
  };

  const getIsLoadTemplateData = (templateCategory: TemplateCategory) => {
    if (templateCategory === "tax") {
      return isLoadedTaxTemplateData;
    }

    if (templateCategory === "freightCost") {
      return isLoadedFreightTemplateData;
    }

    if (templateCategory === "domesticCost") {
      return isLoadedDomesticTemplateData;
    }

    if (templateCategory === "localCost") {
      return isLoadedLocalTemplateData;
    }

    if (templateCategory === "inlandCost") {
      return isLoadedInlandTemplateData;
    }

    if (templateCategory === "otherCost") {
      return isLoadedOtherTemplateData;
    }

    if (checkIsWithdrawalTemplate(templateCategory)) {
      return isLoadedWithdrawalTemplateData;
    }

    return isLoadedAllTemplateData;
  };

  return {
    getSaveStatus,
    setIsLoadTemplateData,
    getIsLoadTemplateData,
    changeIsLoadTemplateToFalseExceptAll,
  };
}
