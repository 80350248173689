import { ChangeEvent, useCallback, useMemo, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

export interface RadioGroupFilterOptionList<T extends string | number> {
  label: string | number;
  value: T;
}

export default function useRadioGroupFilter<T extends string | number>({
  radioGroupFilterOptionList,
  label,
  afterRadioChangeCallback,
  defaultValue,
}: {
  radioGroupFilterOptionList: RadioGroupFilterOptionList<T>[];
  label?: string;
  afterRadioChangeCallback?: () => void;
  defaultValue?: T;
}) {
  const [radioGroupFilter, setRadioGroupFilter] = useState<
    RadioGroupFilterOptionList<T> | undefined
  >(
    defaultValue
      ? radioGroupFilterOptionList.find((v) => {
          return v.value === defaultValue;
        })
      : radioGroupFilterOptionList[0]
  );

  const handleRadioGroupFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      const radioGroupFilterValue = radioGroupFilterOptionList.find(
        (v) => v.value === value
      );

      if (radioGroupFilterValue) {
        afterRadioChangeCallback && afterRadioChangeCallback();
        setRadioGroupFilter(radioGroupFilterValue);
      }
    },
    [afterRadioChangeCallback, radioGroupFilterOptionList]
  );

  const RadioGroupFilterPanel = useMemo(() => {
    return (
      <FormControl>
        <RadioGroup
          row
          onChange={handleRadioGroupFilterChange}
          value={radioGroupFilter?.value}
        >
          <FormLabel component="legend">{label}</FormLabel>

          {radioGroupFilterOptionList.map((radioGroupOptionItem) => {
            return (
              <FormControlLabel
                key={radioGroupOptionItem.value}
                value={radioGroupOptionItem.value}
                control={<Radio />}
                label={radioGroupOptionItem.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }, [
    handleRadioGroupFilterChange,
    label,
    radioGroupFilter?.value,
    radioGroupFilterOptionList,
  ]);

  return {
    RadioGroupFilterPanel,
    radioGroupFilter,
  };
}
