import { useCallback, useMemo, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";

export interface TermSearchType<T extends string> {
  label: string;
  value: T;
  placeHolder?: string;
  type?: string;
}

export default function useSearchWithTermWithHistorySave<T extends string>({
  termSearchTypeOptions,
  key = "adminBidFilter",
  history,
  historyData,
}: {
  termSearchTypeOptions: TermSearchType<T>[];
  key?: string;
  history?: {
    itemKey: string;
    itemKeyIndex: number;
  };
  historyData?: unknown;
}) {
  const [termSearchType, setTermSearchType] = useState<
    TermSearchType<T> | undefined
  >(termSearchTypeOptions[history?.itemKeyIndex ?? 0]);

  const [searchTerm, setSearchTerm] = useState(historyData ?? "");

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const debouncedSearchTermWithObject = useMemo(() => {
    if (termSearchType && debouncedSearchTerm) {
      return { [termSearchType.value]: debouncedSearchTerm };
    }
  }, [debouncedSearchTerm, termSearchType]);

  const handleTermSearchTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      const target = termSearchTypeOptions.find(
        (st) => st.value === event.target.value
      );

      if (target) {
        setTermSearchType(target);
        setSearchTerm("");
      }
    },
    [termSearchTypeOptions]
  );

  const hasValue = useMemo(() => {
    return !!searchTerm;
  }, [searchTerm]);

  const reset = useCallback(
    (e) => {
      e.stopPropagation();
      setSearchTerm("");
      setTermSearchType(termSearchTypeOptions[0]);
    },
    [termSearchTypeOptions]
  );

  const handleSearchTermType = () => {
    alert("숫자만 입력해주세요");
    setSearchTerm("");
  };

  const handleSearchTermChange = useCallback(
    (e) => {
      if (termSearchType?.type) {
        return termSearchType.type === "number" && isNaN(Number(e.target.value))
          ? handleSearchTermType()
          : setSearchTerm(e.target.value);
      }
      return setSearchTerm(e.target.value);
    },
    [termSearchType?.type]
  );

  const TermSearchPanel = useMemo(() => {
    return (
      <Box
        sx={{
          paddingTop: 1.5,
        }}
      >
        <FormControl
          className="search-type"
          variant="outlined"
          sx={{ mr: 0.5, position: "relative" }}
        >
          <InputLabel id="search-term-select-label">검색 대상</InputLabel>

          <Select
            labelId="search-term-select-label"
            id="search-term-select"
            value={termSearchType?.value || ""}
            label="검색 대상"
            onChange={handleTermSearchTypeChange}
            size="small"
          >
            {termSearchTypeOptions.map((st, i) => (
              <MenuItem value={st.value} key={i}>
                {st.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label={termSearchType?.placeHolder || "검색어"}
          size="small"
          value={searchTerm}
          onChange={handleSearchTermChange}
          sx={{ minWidth: 350 }}
        />

        {hasValue && (
          <IconButton
            className="clear"
            onClick={reset}
            sx={{ position: "absolute", mb: 0.3 }}
          >
            <HighlightOffIcon color="action" fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  }, [
    termSearchType?.value,
    termSearchType?.placeHolder,
    handleTermSearchTypeChange,
    termSearchTypeOptions,
    searchTerm,
    handleSearchTermChange,
    hasValue,
    reset,
  ]);

  return {
    TermSearchPanel,
    debouncedSearchTerm,
    termSearchType,
    reset,
    debouncedSearchTermWithObject,
  };
}
