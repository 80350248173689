import { Paper, PaperProps } from "@mui/material";
import styled, { css } from "styled-components";

const toolbarList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px 8px;
  gap: 16px;

  > .left,
  > .right {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const extraFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  position: fixed;
  bottom: 6px;
`;

const extraFooterItem = styled(Paper)<PaperProps & { canClick?: boolean }>(
  ({ canClick }) => css`
    padding: 14px;
    text-align: center;
    min-width: 110px;
    background-color: #f6e9b4 !important;
    cursor: ${canClick ? "pointer" : "inherit"};

    &:not(:last-child) {
      margin-right: 20px;
    }

    > .label {
      font-size: 14px;
      color: rgb(97, 97, 97);
    }

    > .value {
      margin-top: 14px;
      font-size: 20px;
      color: rgb(33, 33, 33);
      font-weight: bold;
    }
  `
);

const headCell = styled.div`
  display: flex;
  align-items: center;

  > .filter {
    margin-left: 2px;
  }
`;

const editRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    cursor: pointer;
  }
`;

const scrollWrapper = styled.div<{
  isPaginationVisible: boolean;
  minWidth?: number;
  scrollbarSize: "default" | "large";
}>`
  min-width: ${({ minWidth }) =>
    typeof minWidth === "number" ? `${minWidth}px` : "750px"};
  overflow-x: ${(props) => (props.isPaginationVisible ? `hidden` : `scroll`)};
  overflow-y: hidden;
  height: 20px;
  position: sticky;
  bottom: 8px;

  &::-webkit-scrollbar {
    height: ${({ scrollbarSize }) =>
      scrollbarSize === "large" ? "16px" : "8px"} !important;
  }
`;

const scroll = styled.div<{
  tableWidth: number;
}>`
  width: ${(props) => `${props.tableWidth}`}px;
  height: 20px;
`;

export default {
  toolbarList,
  extraFooter,
  extraFooterItem,
  headCell,
  editRow,
  scrollWrapper,
  scroll,
};
