import { useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  InputAdornment,
  TextField as MUITextField,
  TextFieldProps,
} from "@mui/material";

export default function TextField({
  handleEnterKeyPress,
  handleTextFieldClear,
  ...props
}: TextFieldProps & {
  handleEnterKeyPress?: () => any;
  handleTextFieldClear?: () => void;
}) {
  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (handleEnterKeyPress) {
        if (e.key === "Enter") {
          handleEnterKeyPress();
        }
      }
    },
    [handleEnterKeyPress]
  );

  return (
    <MUITextField
      {...props}
      onKeyPress={handleKeyPress}
      InputProps={{
        ...props.InputProps,
        ...(handleTextFieldClear && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTextFieldClear}>
                <CloseIcon sx={{ color: "red", fontSize: 20 }} />
              </IconButton>
            </InputAdornment>
          ),
        }),
      }}
    />
  );
}
