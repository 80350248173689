import { Chip, Grid } from "@mui/material";

import { ShipmentCreateContainersInfo } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";

import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../../components/SADSTable";

type CellKey = "NO" | "품명" | "컨테이너" | "위험물";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "NO", label: "NO", align: "right", width: 70 },
  { cellKey: "품명", label: "품명", width: 200 },
  { cellKey: "컨테이너", label: "컨테이너", align: "right", width: 100 },
  { cellKey: "위험물", label: "위험물", align: "right", width: 100 },
];

export default function ItemListForFCL({
  containerAccessable,
  containersInfo,
}: {
  containerAccessable: boolean;
  containersInfo: ShipmentCreateContainersInfo[];
}) {
  const bodyRowList: TableBodyRow<CellKey>[] = (containersInfo || []).map(
    (item, i) => {
      // PO에서 의뢰생성 하는경우 item.name이 없고 item.products[]에 name이 있다.
      const itemName =
        item.name || item.products?.map((v) => v.productName).join(",");

      return {
        rowKey: i,
        NO: { value: i + 1 },
        품명: { value: itemName },
        컨테이너: {
          value: (
            <Grid container direction={"column"}>
              {item.containerList?.map((v, i) => (
                <Grid item key={i}>
                  {v.containerType} X {v.quantity}
                </Grid>
              ))}
            </Grid>
          ),
        },
        위험물: {
          value: changeBooleanValueToKr(item.isDangerous),
        },
      };
    }
  );

  return (
    <Grid container spacing={2} sx={{ padding: "50px" }}>
      <Grid item xs={12}>
        <Chip color="primary" label="컨테이너 상세정보" variant="outlined" />
      </Grid>

      <Grid item xs={7} maxWidth={"800px"}>
        <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
      </Grid>
    </Grid>
  );
}
