import { ReactNode, useCallback, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Control } from "react-hook-form";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import {
  GET_FULLFILLMENT_MANAGERS_RES,
  UPDATE_USER_DETAIL_REQ,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidUser";
import { UPDATE_TEAM_DETAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminTeam";
import { GET_ADMIN_USER_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminUser";
import ADMIN_BID_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_USER_QUERY";
import ADMIN_TEAM_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_TEAM_QUERY";
import {
  DetailType,
  TeamAndUserDetail,
} from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { CorpSizeType } from "@sellernote/_shared/src/types/forwarding/adminTeam";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { changeTeamStatusToKr } from "@sellernote/_shared/src/utils/forwarding/adminTeam";
import AdminDetailDescription from "@sellernote/_shared-for-forwarding-admin/src/components/AdminDetailDescription";

import useSnackbar from "../../../hooks/useSnackbar";
import useHandleManager from "./hooks/useHandleManager";

import TextFieldWithReactHookForm from "../../TextFieldWithReactHookForm";
import IssueCouponModal from "./IssueCouponModal";

function BasicInfo({
  detailData,
  adminList,
  type,
  fullfillmentManagerData,
  refetchDetailData,
  isEditMode,
  control,
}: {
  detailData: TeamAndUserDetail;
  adminList: GET_ADMIN_USER_LIST_RES;
  type: DetailType;
  fullfillmentManagerData: GET_FULLFILLMENT_MANAGERS_RES;
  refetchDetailData: () => void;
  isEditMode: boolean;
  control: Control<UPDATE_TEAM_DETAIL_REQ | UPDATE_USER_DETAIL_REQ>;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [otpToken, setOtpToken] = useState("");
  const [opensIssueCouponModal, setOpensIssueCouponModal] = useState(false);

  const { handleManagerAssign } = useHandleManager({
    type,
    teamId: detailData.teamId ?? 0,
    id: detailData.id ?? 0,
    refetchDetailData,
    onSnackbarOpen: handleSnackbarOpen,
  });

  const { mutate: createUserOtp } = ADMIN_BID_USER_QUERY.useCreateUserOtp();

  /**
   * 팀 관리, 사용자 관리에서 세그먼트를 변경할 때 개인/팀 구분없이 teamId를 전달
   * 팀이 없는 유저도 하나의 팀으로 보기 때문
   */
  const { mutate: updateTeamCorpSizeType } =
    ADMIN_TEAM_QUERY.useUpdateTeamCorpSizeType({
      teamId: type === "team" ? detailData.id ?? 0 : detailData.teamId ?? 0,
      onSuccess: () => {
        handleSnackbarOpen("요청에 성공했습니다.");
        refetchDetailData();
      },
    });

  const handleOtpCreate = useCallback(() => {
    if (!detailData.email) {
      return;
    }

    createUserOtp(
      { targetEmail: detailData.email },
      {
        onSuccess: ({ data: successData }) => {
          handleSnackbarOpen("OTP가 생성되었습니다.");
          setOtpToken(successData.token);
        },
        onError: () => {
          handleSnackbarOpen("OTP 생성에 실패했습니다.", "error");
        },
      }
    );
  }, [createUserOtp, detailData.email, handleSnackbarOpen]);

  const masterInfo = useMemo(() => {
    return detailData?.members?.find((v) => {
      return v.permission.teamRole === "master";
    });
  }, [detailData?.members]);

  const detailDescriptionValueList = useMemo(() => {
    const descriptionList:
      | {
          label: string;
          value: ReactNode;
          gridSize?: number;
          editComponent?: ReactNode;
        }[]
      | undefined = [
      {
        label: "번호",
        value: type === "user" ? detailData.userId : detailData.id,
        gridSize: 4,
      },
      {
        label: "상태",
        value:
          type === "user" ? "정상" : changeTeamStatusToKr(detailData?.status),
        gridSize: 4,
      },
      {
        label: type === "user" ? "가입일" : "팀 생성일",
        value: toFormattedDate(detailData.createdAt),
        gridSize: 4,
      },
      {
        label: "유입구분",
        value: detailData.isKita ? "KITA" : "-",
        gridSize: 4,
      },
      {
        label: "팀명",
        value: detailData.teamName,
        editComponent: (
          <TextFieldWithReactHookForm
            name={"name"}
            control={control}
            defaultValue={detailData.teamName}
          />
        ),
        gridSize: 4,
      },
      { label: "국가명", value: detailData.country, gridSize: 4 },
      {
        label: "멤버 수",
        value: type === "user" ? "1" : detailData.numberMembers,
        gridSize: 4,
      },
      {
        label: type === "user" ? "담당자 이름" : "마스터 이름",
        value: type === "user" ? detailData?.userName : masterInfo?.name,
        gridSize: 4,
        editComponent:
          type === "user" ? (
            <TextFieldWithReactHookForm
              name={"userName"}
              control={control}
              defaultValue={detailData.userName}
            />
          ) : undefined,
      },
      {
        label: "연락처",
        value: type === "user" ? detailData?.phone : masterInfo?.phone,
        gridSize: 4,
        editComponent:
          type === "user" ? (
            <TextFieldWithReactHookForm
              name={"phone"}
              control={control}
              defaultValue={detailData.phone}
            />
          ) : undefined,
      },
      {
        label: "이메일",
        value: type === "user" ? detailData?.email : masterInfo?.email,
        gridSize: 4,
        editComponent:
          type === "user" ? (
            <TextFieldWithReactHookForm
              name={"email"}
              control={control}
              defaultValue={detailData.email}
            />
          ) : undefined,
      },
      {
        label: "팩스번호",
        value: detailData.fax,
        gridSize: 4,
        editComponent: (
          <TextFieldWithReactHookForm
            name={"fax"}
            control={control}
            defaultValue={detailData.fax}
          />
        ),
      },
      {
        label: "포워딩 담당자",
        value: (
          <FormControl size="small" sx={{ minWidth: 180, mt: -1 }}>
            <InputLabel>담당자를 선택하세요.</InputLabel>

            <Select
              value={detailData.forwardingManagerId}
              label="담당자를 선택하세요."
              onChange={(e) => {
                handleManagerAssign(Number(e.target.value), "forwarding");
              }}
            >
              {adminList.list
                .filter((v) => {
                  return v.isForwardingManager;
                })
                .map((v) => {
                  return (
                    <MenuItem key={v.id} value={v.id}>
                      {v.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        ),
        gridSize: 4,
      },
      {
        label: "수출 포워딩 담당자",
        value: (
          <FormControl size="small" sx={{ minWidth: 180, mt: -1 }}>
            <InputLabel>담당자를 선택하세요.</InputLabel>

            <Select
              value={detailData.exportForwardingManagerId}
              label="담당자를 선택하세요."
              onChange={(e) => {
                handleManagerAssign(Number(e.target.value), "exportForwarding");
              }}
            >
              {adminList.list
                .filter((v) => v.isForwardingManager)
                .map((v) => (
                  <MenuItem key={v.id} value={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
              <MenuItem value={0}>담당자 없음</MenuItem>
            </Select>
          </FormControl>
        ),
        gridSize: 4,
      },
      {
        label: "풀필먼트 담당자",
        value: (
          <FormControl size="small" sx={{ minWidth: 180, mt: -1 }}>
            <InputLabel>담당자를 선택하세요.</InputLabel>

            <Select
              value={detailData.fulfillmentManagerId || 0}
              label="담당자를 선택하세요."
              onChange={(e) => {
                handleManagerAssign(Number(e.target.value), "fullfillment");
              }}
            >
              {fullfillmentManagerData.map((v) => {
                return (
                  <MenuItem key={v.id} value={v.id}>
                    {v.name}
                  </MenuItem>
                );
              })}
              <MenuItem value={0}>담당자 없음</MenuItem>
            </Select>
          </FormControl>
        ),
        gridSize: 4,
      },

      {
        label: "쿠폰 발급",
        value: (
          <Button
            variant="outlined"
            onClick={() => setOpensIssueCouponModal(true)}
          >
            쿠폰발급
          </Button>
        ),
        gridSize: 4,
      },

      {
        label: "세그먼트",
        value: (
          <FormControl size="small" sx={{ minWidth: 180, mt: -1 }}>
            <InputLabel>세그먼트를 선택하세요</InputLabel>

            <Select
              value={detailData.corpSizeType}
              label="세그먼트를 선택하세요"
              onChange={(e: SelectChangeEvent<CorpSizeType>) => {
                updateTeamCorpSizeType({
                  corpSizeType: e.target.value as CorpSizeType,
                });
              }}
            >
              <MenuItem value={"SME"}>SME</MenuItem>
              <MenuItem value={"BIG"}>BIG</MenuItem>
            </Select>
          </FormControl>
        ),
        gridSize: 4,
      },
    ];

    if (type === "user") {
      const descriptionListWithOtpButton = [
        ...descriptionList,
        {
          label: "OTP생성",
          value: (
            <Button
              size="small"
              variant={"contained"}
              onClick={handleOtpCreate}
            >
              OTP 생성
            </Button>
          ),
          gridSize: 8,
        },
      ].filter((v) => {
        return v.label !== "팀명";
      });

      if (!otpToken) {
        return descriptionListWithOtpButton;
      }

      return [
        ...descriptionListWithOtpButton,
        {
          label: "OTP",
          value: (
            <Box>
              {otpToken}

              <CopyToClipboard
                text={otpToken}
                onCopy={() => handleSnackbarOpen("복사되었습니다.")}
              >
                <IconButton>
                  <FileCopyOutlinedIcon />
                </IconButton>
              </CopyToClipboard>
            </Box>
          ),
          otpToken,
          gridSize: 12,
        },
      ];
    }

    return descriptionList.filter((v) => {
      return v.label !== "유입구분" && v.label !== "팩스번호";
    });
  }, [
    type,
    detailData.userId,
    detailData.id,
    detailData?.status,
    detailData.createdAt,
    detailData.isKita,
    detailData.teamName,
    detailData.country,
    detailData.numberMembers,
    detailData.userName,
    detailData.phone,
    detailData.email,
    detailData.fax,
    detailData.forwardingManagerId,
    detailData.exportForwardingManagerId,
    detailData.fulfillmentManagerId,
    detailData.corpSizeType,
    control,
    masterInfo?.name,
    masterInfo?.phone,
    masterInfo?.email,
    adminList.list,
    fullfillmentManagerData,
    handleManagerAssign,
    updateTeamCorpSizeType,
    handleOtpCreate,
    otpToken,
    handleSnackbarOpen,
  ]);

  return (
    <>
      <AdminDetailDescription
        title={type === "user" ? "사용자 정보" : "팀 정보"}
        descriptionValueList={detailDescriptionValueList}
        isEditMode={isEditMode}
      />

      {opensIssueCouponModal && (
        <IssueCouponModal
          opensIssueCouponModal={opensIssueCouponModal}
          setOpensIssueCouponModal={setOpensIssueCouponModal}
          teamId={
            type === "user"
              ? (detailData.teamId as number)
              : (detailData.id as number)
          }
        />
      )}
    </>
  );
}

export default BasicInfo;
