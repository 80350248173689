import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";

/**
 * 테이블의 개수, 세액, 총액 View를 담당하는 컴포넌트
 * 운영트렐로 > 매입, 매출관리에서 사용이지만 다른 테이블에서도 사용할 수도 있음
 * showsOnlyFinalPrice true일 땐 함게액만 표시한다.
 */
export default function CheckedListPriceSummary({
  numberCheckedItems,
  totalPrice,
  vatPrice,
  showsOnlyFinalPrice,
  finalPrice,
}: {
  numberCheckedItems: number;
  totalPrice?: number;
  vatPrice?: number;
  showsOnlyFinalPrice?: boolean;
  finalPrice?: number;
}) {
  return (
    <Box
      sx={{
        width: "100%",
        height: 50,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid container>
        <Grid
          item
          display={"flex"}
          alignItems={"center"}
          gap={"10px"}
          minWidth={"150px"}
        >
          <Typography color={grey[500]}>선택항목 :</Typography>

          <Typography>{numberCheckedItems}개</Typography>
        </Grid>

        {!showsOnlyFinalPrice && (
          <>
            <Grid
              item
              md={2}
              display={"flex"}
              alignItems={"center"}
              gap={"10px"}
            >
              <Typography color={grey[500]}>공급가액 :</Typography>

              <Typography>{toThousandUnitFormat(totalPrice)}원</Typography>
            </Grid>

            <Grid
              item
              md={2}
              display={"flex"}
              alignItems={"center"}
              gap={"10px"}
            >
              <Typography color={grey[500]}>세액 :</Typography>

              <Typography>{toThousandUnitFormat(vatPrice)}원</Typography>
            </Grid>
          </>
        )}

        <Grid item md={2} display={"flex"} alignItems={"center"} gap={"10px"}>
          <CheckCircleIcon fontSize="small" color="primary" />

          <Typography>합계 :</Typography>

          <Typography color="primary">
            {!showsOnlyFinalPrice && vatPrice && totalPrice
              ? toThousandUnitFormat(vatPrice + totalPrice)
              : toThousandUnitFormat(finalPrice)}
            원
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
