import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { AlertColor } from "@mui/material";
import axios from "axios";

import { GET_PRESIGNED_URL_RES_DATA } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminFile";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import { FileTypes } from "@sellernote/_shared/src/types/common/upload";

export default function useFileUpload() {
  const history = useHistory();

  const [fileList, setFileList] = useState<FileTypes[]>([]);

  const { mutate: getPresignedURL } = ADMIN_FILE_QUERY.useGetPresignedURL({
    isPermission: false,
  });
  const { mutate: bindFileAfterUpload } =
    ADMIN_FILE_QUERY.useBindFileAfterUpload();

  const handleFileUpload = useCallback(
    ({
      POId,
      teamId,
      onSnackbarOpen,
    }: {
      POId: number;
      teamId: number;
      onSnackbarOpen: (message: string, color?: AlertColor) => void;
    }) => {
      const requestFileData = fileList.map((v: FileTypes) => {
        return {
          mimeType: v.fileInfo.type,
          name: v.fileInfo.name,
          type: "purchaseOrder",
        };
      });

      const flatS3Data = fileList.flat();

      getPresignedURL(
        {
          presignedUrlOptions: requestFileData,
          isPublic: false,
          targetId: POId,
        },
        {
          onSuccess: async ({ data: successData }) => {
            const updateRequests = successData.map(async (param, index) => {
              const response = await axios.put(
                param.url,
                flatS3Data[index].fileInfo,
                {
                  headers: { "Content-Type": flatS3Data[index].fileInfo.type },
                }
              );
              return response;
            });

            try {
              await Promise.all(updateRequests);

              const bindingData = successData.map(
                (v: GET_PRESIGNED_URL_RES_DATA) => {
                  return {
                    key: v.key,
                    domain: "purchaseOrder",
                    targetId: POId,
                    teamId: teamId,
                  };
                }
              );

              bindFileAfterUpload(
                { bindList: bindingData },
                {
                  onSuccess: () => {
                    setFileList([]);
                    history.push(`/order/${POId}`);
                  },
                  onError: () => {
                    onSnackbarOpen("요청에 실패했습니다.", "error");
                  },
                }
              );
            } catch (error) {
              onSnackbarOpen("요청에 실패했습니다.", "error");
            }
          },

          onError: () => {
            onSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    },
    [bindFileAfterUpload, fileList, getPresignedURL, history]
  );

  return { handleFileUpload, fileList, setFileList };
}
