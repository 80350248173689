import React from "react";
import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { checkIsOfficeFile } from "@sellernote/_shared/src/utils/common/etc";

import Styled from "./index.styles";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdfWorker.js";

function FileViewer({
  isHwp,
  isPdf,
  fileExtension,
  imageWidth,
  fileUrl,
  hwpRenderDivRef,
  handleDocumentLoadSuccess,
  pdfScale,
  pageNumber,
  iframeHeight,
}: {
  isHwp: boolean;
  isPdf: boolean;
  fileExtension: string;
  imageWidth: number;
  fileUrl: string;
  hwpRenderDivRef: React.RefObject<HTMLDivElement>;
  handleDocumentLoadSuccess: ({
    numPages,
  }: {
    numPages: number | null;
  }) => void;
  pdfScale: number;
  pageNumber: number;
  iframeHeight?: number;
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!isHwp && !isPdf && !checkIsOfficeFile(fileExtension) && (
        <>
          <img
            style={{ width: `${imageWidth}%` }}
            src={fileUrl}
            alt="NOT SUPPORTED"
          />
        </>
      )}

      {isHwp && <div ref={hwpRenderDivRef} />}

      {checkIsOfficeFile(fileExtension) && (
        <iframe
          title="office document"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
          width={800}
          height={iframeHeight || 1000}
        ></iframe>
      )}

      {isPdf && (
        <Styled.pdfWrapper>
          <Document
            file={fileUrl}
            onLoadSuccess={handleDocumentLoadSuccess}
            // 비 라틴 문자 렌더링 에러 수정 코드
            options={{
              cMapPacked: true,
              cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
            }}
          >
            <Page scale={pdfScale} pageNumber={pageNumber} />
          </Document>
        </Styled.pdfWrapper>
      )}
    </div>
  );
}

export default FileViewer;
