import { ReactNode, SyntheticEvent, useState } from "react";
import { Box, Tab, Tabs as MUITabs } from "@mui/material";

interface TabsData {
  tabLabel: string | number;
  tabPanelItem: ReactNode;
  disabled?: boolean;
}

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Tabs({
  tabsData,
  defaultTabPanelValue,
}: {
  tabsData: TabsData[];
  defaultTabPanelValue?: number;
}) {
  const [tabPanelValue, setTapPanelValue] = useState(defaultTabPanelValue ?? 0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTapPanelValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MUITabs
          variant="scrollable"
          scrollButtons="auto"
          value={tabPanelValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabsData.map((v, index) => {
            return (
              <Tab
                disabled={v.disabled}
                key={v.tabLabel}
                label={v.tabLabel}
                {...a11yProps(index)}
              />
            );
          })}
        </MUITabs>
      </Box>

      {tabsData.map((v, index) => {
        return (
          <TabPanel key={v.tabLabel} value={tabPanelValue} index={index}>
            {v.tabPanelItem}
          </TabPanel>
        );
      })}
    </Box>
  );
}
