import { Box, Button } from "@mui/material";

import SelectItem from "./SelectItem";

interface MultiLevelSelectOption<V extends string | number> {
  label: string;
  value: V;
  dependentSelectInfo?: MultiLevelSelectInfo<V>;
}

interface MultiLevelSelectInfo<V extends string | number> {
  title: string;
  optionList: MultiLevelSelectOption<V>[];
  selectedOptionValue: V | undefined;
  handleSelect: (v: V | undefined) => void;
  /** Select의 With (px) */
  width?: number;
}

export type { MultiLevelSelectInfo, MultiLevelSelectOption };

export default function MultiLevelSelect<V extends string | number>({
  selectInfo: rootSelectInfo,
  onReset,
  onChangeCallback,
  shouldSubSelectHasDefaultValue,
}: {
  selectInfo: MultiLevelSelectInfo<V>;
  onReset?: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => void;
  onChangeCallback?: (value: string | number) => void;
  shouldSubSelectHasDefaultValue?: boolean;
}) {
  return (
    <Box>
      {onReset && (
        <Button variant="outlined" onClick={(event) => onReset(event)}>
          초기화
        </Button>
      )}

      <SelectItem
        selectInfo={rootSelectInfo}
        onChangeCallback={onChangeCallback}
        shouldSubSelectHasDefaultValue={shouldSubSelectHasDefaultValue}
      />
    </Box>
  );
}
