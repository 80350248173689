import { useCallback, useMemo, useState } from "react";

import {
  VESSEL_DESTINATION_SELECT_OPTION_LIST,
  VESSEL_DESTINATION_SELECT_OPTION_LIST_FOR_AIR,
} from "@sellernote/_shared/src/constants/forwarding/vesselSchedule";
import ADMIN_VESSEL_SCHEDULE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_VESSEL_SCHEDULE_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import {
  VESSEL_CARRIER,
  VESSEL_DESTINATION,
  VESSEL_ORIGIN,
  VESSEL_ORIGIN_ITEM,
} from "@sellernote/_shared/src/types/forwarding/vesselSchedule";
import { getLabelForCarrier } from "@sellernote/_shared/src/utils/forwarding/adminBid";
import {
  MultiLevelSelectInfo,
  MultiLevelSelectOption,
} from "@sellernote/_shared-for-admin/src/components/MultiLevelSelect";

type MultiLevelSelectType = VESSEL_CARRIER | VESSEL_ORIGIN | VESSEL_DESTINATION;

export default function useMultiLevelSelectData({
  freightType,
  includesWarehouse,
}: {
  freightType: FreightType;
  includesWarehouse: boolean;
}) {
  const [carrier, setCarrier] = useState<MultiLevelSelectType | undefined>(
    undefined
  );

  const [origin, setOrigin] = useState<MultiLevelSelectType | undefined>(
    undefined
  );

  const [destination, setDestination] = useState<
    MultiLevelSelectType | undefined
  >(undefined);

  const [warehouseName, setWarehouseName] = useState<
    MultiLevelSelectType | undefined
  >(undefined);

  const { data } = ADMIN_VESSEL_SCHEDULE_QUERY.useGetVesselScheduleSelectList({
    freightType,
    includeWarehouse: includesWarehouse,
  });

  const getDestinationOptionList = useCallback(
    (polOrWarehouseData: VESSEL_ORIGIN_ITEM[] | undefined) => {
      return polOrWarehouseData?.map((polOrWarehouseItem) => {
        return {
          value: polOrWarehouseItem.code,
          label: polOrWarehouseItem.name,
          dependentSelectInfo: {
            title: "도착지",
            handleSelect: setDestination,
            width: 140,
            selectedOptionValue: destination,
            optionList:
              freightType === "AIR"
                ? VESSEL_DESTINATION_SELECT_OPTION_LIST_FOR_AIR
                : VESSEL_DESTINATION_SELECT_OPTION_LIST,
          },
        };
      });
    },
    [destination, freightType]
  );

  const getWarehouseOptionList = useCallback(
    (
      polData: VESSEL_ORIGIN_ITEM[] | undefined,
      warehouseData: VESSEL_ORIGIN_ITEM[] | undefined
    ) => {
      return polData?.map((polItem) => {
        return {
          value: polItem.code,
          label: polItem.name,
          dependentSelectInfo: {
            title: "창고",
            handleSelect: setWarehouseName,
            width: 140,
            selectedOptionValue: warehouseName,
            optionList: getDestinationOptionList(warehouseData),
          },
        };
      });
    },
    [getDestinationOptionList, warehouseName]
  );

  const getOriginOptionList = useCallback(() => {
    const optionList: MultiLevelSelectOption<MultiLevelSelectType>[] = [];

    for (const key in data) {
      optionList.push({
        label: key,
        value: key as MultiLevelSelectType,
        dependentSelectInfo: {
          title: "출발지",
          handleSelect: setOrigin,
          width: 140,
          selectedOptionValue: origin,
          optionList: includesWarehouse
            ? (getWarehouseOptionList(
                data[key as VESSEL_CARRIER].pol,
                data[key as VESSEL_CARRIER].warehouse?.map((v) => {
                  return { code: v, name: v };
                })
              ) as MultiLevelSelectOption<MultiLevelSelectType>[])
            : (getDestinationOptionList(
                data[key as VESSEL_CARRIER].pol
              ) as MultiLevelSelectOption<MultiLevelSelectType>[]),
        },
      });
    }

    return optionList;
  }, [
    data,
    getDestinationOptionList,
    getWarehouseOptionList,
    includesWarehouse,
    origin,
  ]);

  const MULTI_LEVEL_SELECT_INFO = useMemo(() => {
    if (!data) {
      return;
    }

    const result: MultiLevelSelectInfo<MultiLevelSelectType> = {
      title: getLabelForCarrier(freightType),
      handleSelect: setCarrier,
      selectedOptionValue: carrier,
      width: 140,
      optionList: getOriginOptionList(),
    };
    return result;
  }, [carrier, data, freightType, getOriginOptionList]);

  return {
    MULTI_LEVEL_SELECT_INFO,
    setOrigin,
    setDestination,
    carrier,
    destination,
    setCarrier,
    origin,
    warehouseName,
    setWarehouseName,
  };
}
