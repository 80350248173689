import { Chip, Divider, Grid } from "@mui/material";
import { blue } from "@mui/material/colors";

import { BidProductInfo } from "@sellernote/_shared/src/types/forwarding/bid";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import { getPackageLabel } from "@sellernote/_shared/src/utils/forwarding/bid";

export default function ItemListForLCLAndAIR({
  productsInfo,
}: {
  productsInfo: BidProductInfo[];
}) {
  return (
    <Grid container spacing={2} sx={{ padding: "50px" }}>
      <Grid item xs={12}>
        <Chip color="primary" label="컨테이너 상세정보" variant="outlined" />
      </Grid>

      <Grid item container xs={12}>
        <Grid item xs={1}>
          NO
        </Grid>

        <Grid item xs={2}>
          아이템
        </Grid>

        <Grid item xs={2}>
          포장타입
        </Grid>

        <Grid item xs={1}>
          CBM
        </Grid>

        <Grid item xs={1}>
          중량
        </Grid>

        <Grid item xs={2}>
          위험물 여부
        </Grid>

        <Grid item xs={2}>
          2단적재
        </Grid>

        <Grid item xs={1}>
          수량
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ bgcolor: blue[700] }} component={"div"} />
      </Grid>

      {(productsInfo || []).map((item, index) => {
        return (
          <Grid item container xs={12} key={index}>
            <Grid item xs={1}>
              {index + 1}
            </Grid>

            <Grid item xs={2}>
              {item.name}
            </Grid>

            <Grid item xs={2}>
              {getPackageLabel(item.packingType)}
            </Grid>

            <Grid item xs={1}>
              {item.cbm}
            </Grid>

            <Grid item xs={1}>
              {item.weight}
              {item.weightUnit}
            </Grid>

            <Grid item xs={2}>
              {changeBooleanValueToKr(item.isDangerous)}
            </Grid>

            <Grid item xs={2}>
              {/* 의뢰생성에서 넘어오는 경우 해당 값이 아직 isDouble인 상태라서 구분을 둠 */}
              {"canStack" in item
                ? changeBooleanValueToKr(
                    (item as unknown as { canStack: boolean }).canStack
                  )
                : changeBooleanValueToKr(item.isDouble)}
            </Grid>

            <Grid item xs={1}>
              {item.quantity}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
