import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { ShipmentCreateContainersInfo } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { BidProductInfo } from "@sellernote/_shared/src/types/forwarding/bid";

import ItemListForFCL from "./ItemListForFCL";
import ItemListForLCLAndAIR from "./ItemListForLCLAndAIR";

function ItemList({
  freightType,
  containerAccessable,
  containersInfo,
  productsInfo,
}: {
  freightType: FreightType;
  containerAccessable: boolean;
  containersInfo: ShipmentCreateContainersInfo[];
  productsInfo: BidProductInfo[];
}) {
  if (freightType === "FCL") {
    return (
      <ItemListForFCL
        containerAccessable={containerAccessable}
        containersInfo={containersInfo}
      />
    );
  }

  if (freightType === "LCL" || freightType === "AIR") {
    return <ItemListForLCLAndAIR productsInfo={productsInfo} />;
  }

  return null;
}

export default ItemList;
