import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FilterAlt, RestartAlt } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import Portal from "@sellernote/_shared/src/components/Portal";

import Styled from "./index.styles";

export type TableHeadFilterOption<T extends string | number | boolean> = {
  label: string | number;
  value: T;
};

export default function useTableHeadFilter<
  T extends string | number | boolean
>({
  filterOptions,
  onFilterChange,
}: {
  filterOptions: TableHeadFilterOption<T>[];
  onFilterChange?: (filter: T[]) => void;
}) {
  const [filter, setFilter] = useState<T[]>([]);
  const [draftFilter, setDraftFilter] = useState<T[]>(filter);

  const initDraftFilter = useCallback(() => {
    setDraftFilter([...filter]);
  }, [setDraftFilter, filter]);

  // filter가 변경되면 draft도 초기화
  useEffect(() => {
    initDraftFilter();
  }, [filter, initDraftFilter]);

  // filter가 변경될때 callback 실행
  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(filter);
    }
  }, [filter, onFilterChange]);

  const [visibleFilterDetail, setVisibleFilterDetail] = useState(false);

  const handleFilterClick = useCallback(() => {
    setVisibleFilterDetail(!visibleFilterDetail);
  }, [setVisibleFilterDetail, visibleFilterDetail]);

  const handleFilterDetailLeave = useCallback(() => {
    setVisibleFilterDetail(false);

    initDraftFilter();
  }, [setVisibleFilterDetail, initDraftFilter]);

  const handleFilterReset = useCallback(() => {
    setFilter([]);
    setVisibleFilterDetail(false);
  }, [setFilter]);

  const handleFilterSubmit = useCallback(() => {
    setFilter(draftFilter);
    setVisibleFilterDetail(false);
  }, [draftFilter, setFilter, setVisibleFilterDetail]);

  /**
   * table이 필터사이즈보다 작을경우 필터화면이 잘리는 문제가 있어서 Portal을 사용했음.
   * 필터 아이콘의 위치를 찾아 아래의 위치에 필터화면을 배치.
   */
  const filterTriggerRef = useRef<SVGSVGElement>(null);
  const filterTriggerPosition = (() => {
    const target = filterTriggerRef.current;
    if (!target) {
      return null;
    }

    return target.getClientRects()[0];
  })();

  const FilterPanel = useMemo(() => {
    return (
      <Styled.filterPanel>
        <FilterAlt
          ref={filterTriggerRef}
          fontSize="small"
          onClick={handleFilterClick}
          color={filter.length ? "primary" : "disabled"}
          style={{ cursor: "pointer" }}
        />

        {visibleFilterDetail && (
          <Portal selector="#app-portal">
            <Styled.filterDetail
              onMouseLeave={handleFilterDetailLeave}
              style={{
                top: filterTriggerPosition?.top ?? 0,
                left: (filterTriggerPosition?.left ?? 0) + 20,
                right: "100%",
              }}
            >
              <Card style={{ backgroundColor: "#eeeeee" }}>
                <CardContent>
                  {!!filter.length && (
                    <div className="clear" onClick={handleFilterReset}>
                      <RestartAlt color="primary" fontSize="medium" />
                    </div>
                  )}

                  <div className="list">
                    {filterOptions.map((v) => {
                      const filtered = draftFilter.includes(v.value);

                      return (
                        <div className="item" key={v.label}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={filtered}
                                onClick={(e) => {
                                  e.stopPropagation();

                                  if (filtered) {
                                    setDraftFilter(
                                      draftFilter.filter((fv) => fv !== v.value)
                                    );
                                  } else {
                                    setDraftFilter([...draftFilter, v.value]);
                                  }
                                }}
                                size="small"
                              />
                            }
                            label={v.label}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <div className="action">
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleFilterDetailLeave}
                    >
                      취소
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleFilterSubmit}
                    >
                      적용
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Styled.filterDetail>
          </Portal>
        )}
      </Styled.filterPanel>
    );
  }, [
    handleFilterClick,
    filter.length,
    visibleFilterDetail,
    handleFilterDetailLeave,
    filterTriggerPosition?.top,
    filterTriggerPosition?.left,
    handleFilterReset,
    filterOptions,
    handleFilterSubmit,
    draftFilter,
  ]);

  return { FilterPanel, setFilter, filter };
}
